export const nav = [
  {
    id: 'rules',
    label: 'Правила использования'
  },
  {
    id: 'personal',
    label: 'Политика обработки и защиты персональных данных'
  },
  {
    id: 'personalProcess',
    label: 'Согласие на обработку персональных данных'
  },
  {
    id: 'offerta',
    label: 'Оферта на предоставление возмездных услуг'
  },
  {
    id: 'warning',
    label: 'Запрещено к пересылке'
  },
  {
    id: 'reklama',
    label: 'Согласие на рекламу для зарегистрированных посетителей'
  }
]
